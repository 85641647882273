.button {
    cursor: pointer;
    border-radius: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s ease all;
}
.button[disabled] {
    opacity: 0.5;
    cursor: default;
}

.button[disabled]:hover {
    background: var(--dark) !important;
    opacity: 0.5;
    cursor: default;
}

.button-outline {
    background: var(--dark) !important;
    border: 1px solid var(--purple) !important;
}

.button-small {
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--gradient);
    border: none;
    width: fit-content;
}

.button-small.no-text {
    padding: 0;
    width: 30px;
}

.button-small:hover {
    transform: translateY(-5px);
    box-shadow: 0 100px 80px rgba(131, 60, 255, 0.3),
    0 41px 33px rgba(131, 60, 255, 0.2),
    0 22px 17px rgba(131, 60, 255, 0.15),
    0 12px 10px rgba(131, 60, 255, 0.1),
    0 6px 5px rgba(131, 60, 255, 0.1),
    0 2px 2px rgba(131, 60, 255, 0.05);
}

.button-small__icon {
    font-size: 20px;
    line-height: 20px;
    display: block;
}

.button-small__text {
    margin-left: 5px;
    margin-right: 5px;
}

.button-outline:hover {
    background-color: var(--purple) !important;
    transform: none !important;
    box-shadow: none !important;
}

.button-big {
    background: var(--gradient);
    border: none;
    height: 40px;
    width: fit-content;
    padding-left: 40px;
    padding-right: 40px;
}

.button-big:hover {
    transform: translateY(-5px);
    box-shadow: 0 100px 80px rgba(131, 60, 255, 0.3),
    0 41px 33px rgba(131, 60, 255, 0.2),
    0 22px 17px rgba(131, 60, 255, 0.15),
    0 12px 10px rgba(131, 60, 255, 0.1),
    0 6px 5px rgba(131, 60, 255, 0.1),
    0 2px 2px rgba(131, 60, 255, 0.05);
}

.button-big.width100 {
    width: 100%;
}

.button-big__icon {
    font-size: 20px;
    line-height: 20px;
    display: block;
    margin-left: 5px;
}