.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--purple);
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-family: var(--font);
    font-size: 14px;
    white-space: nowrap;
}