.input-wrap {
    width: 100%;
}

.input {
    background: var(--dark);
    border: 1px solid var(--purple);
    color: white;
    border-radius: 25px;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    width: 100%;
}

.input.big {
    height: 50px;
    padding-left: 20px;
    padding-right: 120px;
}

