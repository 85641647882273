.header {
    height: 80px;
    background: rgba(23, 24, 34, 0.2);
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
}

.logo-btn {
    border: none;
    outline: none;
    background: none;
}

.menu-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
}

.logo {
    cursor: pointer;
    margin-right: auto;
}

.logo img {
    height: 50px;
}

.header__btns {
    display: flex;
}

.plus-btn {
    margin-left: 10px;
}

.exit-btn {
    margin-left: 10px;
}

.profile-btn {
    margin-left: 10px;
}

.link {
    text-decoration: none;
}