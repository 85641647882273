:root {
    --purple: #833CFF;
    --pink: #FC00FF;
    --bg: #171822;
    --dark: #1E1F31;
    --yellow: #F0B90B;
    --green: #72FF77;
    --red: #FF7272;
    --gradient: linear-gradient(279.17deg, rgba(252, 0, 255, 0.9) 0%, rgba(0, 219, 222, 0.9) 100%);
    --gradient-reverse: linear-gradient(100deg, rgba(252, 0, 255, 0.9) 0%, rgba(0, 219, 222, 0.9) 100%);
    --font: "Inter", sans-serif;
}

.yellow {
    color: var(--yellow);
}

.purple {
    color: var(--purple);
}

.pink {
    color: var(--pink);
}

.red {
    color: var(--red);
}

.green {
    color: var(--green);
}

.text {
    font-family: var(--font);
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: white;
    margin: 0;
}

.title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: white;
}

.small-title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: white;
}