.table-wrap {
    width: 100%;
    overflow-x: auto;
}
.table {
    width: fit-content;
}

.table__row {
    background-color: var(--dark);
    border-radius: 10px;
    padding: 10px 0;
    margin-top: 5px;
}

.table__header {
    color: white;
    display: flex;
}

.table__header__item {
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    font-family: var(--font);
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: content-box;
    white-space: nowrap;
}


.table__row {
    display: flex;
    color: white;
}

.table__row__item {
    font-family: var(--font);
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: content-box;
    border-right: 1px solid rgba(101, 101, 101, 0.5);
}

.table__row__item:last-child {
    border-right: none;
}

.img {
    height: 20px;
}

@media screen and (min-width: 768px){
    .table {
        width: 100%;
    }
    .table__header__item {
        flex: 1;
    }
    .table__row__item {
        flex: 1;
    }
}