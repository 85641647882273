.home {
    margin-top: 20px;
}
.home__profile-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: var(--dark);
    border-radius: 10px;
    border: 1px solid var(--purple);
}

.home__profile__avatar {
    position: relative;
    margin-right: 20px;
    max-width: 50%;
}

.home__profile__avatar__circle {
    border-radius: 50%;
    background: var(--gradient);
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
}

.home__profile__avatar__edit {
    position: absolute;
    right: 3px;
    bottom: 3px;
}

.home__profile__name {
    display: flex;
    align-items: flex-start;
}

.home__profile__name__edit {
    margin-top: -5px;
    margin-right: 10px;
}

.home__profile__name__text {
    text-align: left;
    font-family: 'Press Start 2P', cursive;
    font-size: 10px;
    line-height: 160%;
}

.wallet {
    margin-top: 20px;
}

.wallet__score {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 3fr;
}

.wallet__number {
    font-weight: 600;
    margin-right: 5px;
}

.button-withdraw {
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile-description__title {
    text-align: left;
    margin-bottom: 10px;
}

.profile-description__content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.profile-description__edit {
    margin-right: 10px;
}

.profile-table__title {
    text-align: left;
}

.home__table {
    margin-bottom: 20px;
}

.input-file {
    display: none;
}

.home__profile__name__edit__check {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}

.home__profile__name__edit__check > * {
    margin-bottom: 10px;
}

.home__profile__name__edit__cancel {
    margin-right: 5px;
}

@media screen and (min-width: 600px){
    .home__profile__name__text {
        font-size: 14px;
    }
}