.textarea-wrap {
    width: 100%;
}

.textarea {
    background: var(--dark);
    border: 1px solid var(--purple);
    color: white;
    border-radius: 25px;
    height: 150px;
    padding: 15px;
    outline: none;
    resize: vertical;
    width: 100%;
}
