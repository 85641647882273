.loader-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    z-index: 14;
    display: flex;
    align-items: center;
    justify-content: center;
    /*visibility: hidden;*/
    /*transition: max-height 0.3s, opacity 0.3s, visibility 0.3s;*/
    /*opacity: 0;*/
    /*max-height: 0;*/
}

.loader-text {
    font-family: 'Press Start 2P', cursive;
    font-size: 30px;
    line-height: 160%;
    /*color: var(--purple);*/
    background: -webkit-linear-gradient(120deg, rgba(0, 219, 222), rgba(252, 0, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 330px;
}
