.game-list__title {
    margin-top: 15px;
    margin-bottom: 27px;
}

.game-list__games {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game-wrap {
    position: relative;
    width: 100%;
}

.game-wrap__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.game-wrap__content__title {
    position: relative;
}

.game-wrap__content__soon {
    color: var(--green);
    font-family: var(--font);
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
}


.game {
    position: relative;
    background: #1E1F31;
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    margin-bottom: 22px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.blurred {
    filter: blur(5px);
    opacity: 0.7;
}

.game__column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game__image {
    margin-bottom: 5px;
}

.game__players-count {
    font-family: var(--font);
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-top: 10px;
}

.tooltip-wrap {
    position: absolute;
    top: -10px;
    right: 0;
}