.select-wrap {
    height: 30px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    background: var(--dark);
    border: 1px solid var(--purple);
    border-radius: 25px;
    color: white;
    display: flex;
    align-items: center;
    width: fit-content;
}

.select {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.button-small__icon {
    font-size: 20px;
    line-height: 20px;
    display: block;
    margin-right: 5px;
}