.main__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
}

.main__stats {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
}

.stat {
    margin-right: 15px;
}

.main__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main__title {
    font-family: var(--font);
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
}

.main__text {
    color: white;
    text-align: center;
    margin-top: 20px;
}

.main__currency__wrap {
    margin-top: 20px;
}

.main__currency {
    font-family: var(--font);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
}

.main__currency-money {
    font-family: var(--font);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.players-count {
    font-family: var(--font);
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
}


.link {
    text-decoration: none;
}