.tooltip {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


/*.tooltip:hover .tooltip__content {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*    max-height: 1000px;*/
/*}*/

.tooltip img {
    cursor: pointer;
    height: 17px;
    margin-left: 5px;
}

.tooltip__block {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--purple);
    background: var(--dark);
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.tooltip__content {
    position: absolute;
    width: fit-content;
    max-width: 300px;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
    background-color: var(--dark);
    color: white;
    z-index: 12;
    transition: 0.3s ease opacity;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    font-weight: 300;
    font-size: 14px;
    font-family: var(--font);
}

.tooltip__content.isOpen {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
}

.top {
    bottom: 33px;
}

.bottom {
    top: 33px;
}

.left {
    left: -270px;
}

.right {
    right: -270px;
}
/*{"top":position?.includes("top")}*/