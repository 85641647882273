html, body, #root {
    background: var(--bg);
    font-family: var(--font);
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

img {
    max-width:100%;
}

.content {
    z-index: 1;
    padding-top: 100px;
    position: relative;
}

.gradient-circle {
    background: var(--gradient-reverse);
    opacity: 0.7;
    filter: blur(62.5px);
    position: absolute;
    width: 222px;
    height: 222px;
    z-index: 0;
}

.gradient-circle-1 {
    top: -23px;
    right: -50px;
}