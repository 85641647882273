.add-money-wrap {
    position: fixed;
    left: 0;
    top: -10000px;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    z-index: 14;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    visibility: hidden;
    transition: max-height 0.3s, opacity 0.3s, visibility 0.3s;
    opacity: 0;
    max-height: 0;
}

.add-money {
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: var(--dark);
    padding-bottom: 50px;
    position: relative;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 50px black;

    transform: translateY(500px);
    transition: transform ease-in 0.5s;
}

.add-money-wrap.isOpen {
    top: 0;
    opacity: 1;
    max-height: 100000px;
    visibility: visible;
    transition: max-height 0.5s, opacity 0.3s, visibility 0.3s;
}

.add-money-wrap.isOpen .add-money {
    transform: translateY(0);
    transition: transform 0.5s;
}

.add-money__close {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--dark);
    left: 50%;
    margin-left: -25px;
    top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-money__close i {
    color: white;
    font-size: 30px;
}

.add-money__title {
    margin-top: 10px;
}

.add-money__text {
    text-align: center;
    margin-bottom: 10px;
}

.add-money__input-group {
    margin-top: 20px;
}

.add-money__input-wrap {
    position: relative;
    margin-bottom: 20px;
}

.add-money__input__currency {
    position: absolute;
    right: 20px;
    top: 12px;
}

.add-money__input__available {
    color: white;
    display: block;
    margin-bottom: 5px;
}

.add-money__input__available__value {
    font-weight: bold;
}

.add-money__range-wrap {
    margin-bottom: 20px;
}

.btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}


@media screen and (min-width: 1000px) {
    .add-money {
        padding: 50px;
        max-width: 500px;
        position: absolute;
        left: 50%;
        margin-left: -250px;
        top: 50%;
        margin-top: -300px;
        border-radius: 20px;
    }
}